<template>
  <div>
    <CoolLightBox
      :items="photos"
      :index="index"
      :useZoomBar="true"
      @close="index = null"
    ></CoolLightBox>

    <section id="about" class="section-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h2>Aplicação de Insulfilm de segurança para Janelas</h2>

            <p>
              O insulfilm de segurança para janelas torna o seu vidro mais
              resistente a danos e mais forte, evita danos e acidentes
              familiares e protege os seus bens e entes queridos.
            </p>

            <p>
              A sua aplicação é muito adequada para projetos residenciais,
              escritórios e casas de banho. Em caso de invasão, o filme
              dificulta a entrada na instituição, evitando tentativas de roubo e
              vandalismo.
            </p>

            <p>
              Quando usado no banheiro, sua família estará protegida caso o
              vidro se quebre, pois todos os fragmentos serão fixados no filme
              para evitar acidentes.
            </p>

            <h3>Benefícios do insulfilm de segurança para janelas</h3>

            <ul class="list-disc">
              <li>Anti-vandalismo</li>
              <li>
                Camada anti-risco com maior resistência à abrasão do mercado
              </li>
              <li>A película não rasga, e não pode ser perfurada</li>
              <li>
                Camada protetora para vidros refletivos (Habitat) monolíticos e
                temperados
              </li>
              <li>
                Estabilidade de cor (anti-amarelamento) e proteção UV de no
                mínimo 95%
              </li>
              <li>Processo de fabricação da película, feita 100% nos EUA</li>
            </ul>

            <h4>Modelo PS4</h4>

            <p>
              É recomendado até 120 Kg, exemplo: vidros de janelas, portas de
              vidros e box de banheiros.
            </p>

            <h4>Modelo PS7</h4>

            <p>
              É recomendado a partir de 120 Kg, exemplo: fachadas de vidros,
              vitrines e peças de vidros com 1800mm x 3000mm x 10mm ou acima.
            </p>

            <div @click="index = 0" class="page-photo-container">
              <Photo
                :src="photos[0].thumb"
                :title="photos[0].title"
                :description="photos[0].description"
                :idLocation="photos[0].idLocation"
              />
            </div>
          </div>
          <!-- col end -->

          <div class="col-lg-5 section-content-illustration">
            <QuotationCall position="float" />
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->
      </div>
    </section>
  </div>
</template>

<script>
import LayoutInternal from "@/layouts/LayoutInternal.vue";
import Photo from "@/components/photo/photo.vue";

//Lazy Loading
const QuotationCall = () =>
  import(
    /* webpackChunkName: "QuotationCall" */ "@/components/cotacao/quotation-call.vue"
  );

import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  components: {
    QuotationCall,
    Photo,
    CoolLightBox,
  },

  data() {
    return {
      index: null,
      photos: [
        //segurança
        {
          description:
            "Película de segurança usado para evitar que o vidro se quebre",
          idLocation: 1,
          src: "/images/fotos/seguranca/pelicula-seguranca-vidros.jpg",
          thumb: "/images/fotos/seguranca/pelicula-seguranca-vidros.jpg",
          title: "Película para Janela",
        },
      ],
    };
  },

  created() {
    this.$emit(`update:layout`, LayoutInternal);
    this.$emit(`update:title`, "Insulfilm de segurança para Janelas");
    this.$emit(
      `update:introduction`,
      "Instalamos insulfilm de segurança para janela em comércios, sacadas, escritórios, guaritas e na arquitetura em geral"
    );
    this.$emit(`update:bgHeader`, "black");
  },
};
</script>

<style lang="scss" scoped>
</style>